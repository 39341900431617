import React from 'react'
import FooterHeading from './components/FooterHeading'

function Footer() {
  return (
    <div className='bg-[#4CCD99] p-8 w-full'>
      <FooterHeading/>
    </div>
  )
}

export default Footer
