import React from "react";
import { GrSecure } from "react-icons/gr";
import { IoCall, IoCashOutline } from "react-icons/io5";
import { MdPayment } from "react-icons/md";
import "./Bar.css";

function MainBar() {
  return (
    <div>
      <div className="bg-black pt-4 pb-4 pl-8 pr-8 grid grid-cols-4 small-bar gap-4">
        <div className="flex justify-center">
          <div className="flex items-center gap-2">
            <GrSecure
              style={{
                color: "#4CCD99",
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
            <p className="font-medium text-base text-[#4CCD99]">
              Safe and Secure
            </p>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="flex items-center gap-2">
            <IoCall
              style={{
                color: "#4CCD99",
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
            <p className="font-medium text-base text-[#4CCD99]">
            24x7 Service
            </p>
          </div>
        </div>


        <div className="flex justify-center">
          <div className="flex items-center gap-2">
            <MdPayment
              style={{
                color: "#4CCD99",
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
            <p className="font-medium text-base text-[#4CCD99]">
            Pay on Mobile
            </p>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="flex items-center gap-2">
            <IoCashOutline
              style={{
                color: "#4CCD99",
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
            <p className="font-medium text-base text-[#4CCD99]">
            Win Real Cash
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainBar;
