import React from 'react'
import MainHeader from './components/MainPage/components/MainHeader';
import Navbar from './components/Navbar';
import Bar from './components/Bar';
import Online from './components/Online';
import Footer from './components/Footer';
import "./components/main.css"

function LandingPage() {
  return (
    <div>
       <div>
        <Navbar/>
      </div>
      <MainHeader/>

      <div>
        <Bar/>
      </div>

      <div>
        <Online/>
      </div>

      <div className='w-full data-wide'>
        <Footer/>
      </div>
    </div>
  )
}

export default LandingPage
