import React from 'react'
import { FaRegUserCircle } from 'react-icons/fa'
import "./Bar.css";

function PeopleSay() {
  return (
    <div className='w-[80%] pt-8 pb-8 '>
      <div className='flex justify-center'>

        <p className='font-semibold text-3xl'>What Players Say About Oscar Rummy</p>
      </div>
      <div>
        <div className='lg:grid lg:grid-cols-3 lg:gap-4 sm:grid sm:grid-cols-1 people-say-gap sm:gap-4 mt-8 p-4'>
          <div className='bg-[#4CCD99] rounded-md p-4'>
            <p className='text-center text-sm font-normal text-black'>Participated in Rummy Premier Series and won 5 Lakhs and a Sedan car. My winnings were withdrawn in my account instantly. Oscar Rummy has millions of real players and is the safest platform to play online rummy.</p>

            <div className='flex justify-center mt-4'><FaRegUserCircle style={{
              width: "4rem",
              height: "4rem",
              color: "black"
            }} /></div>

            <div className='flex justify-center flex-col items-center mt-4'>
              <p className='text-base font-medium text-black'>Ajay Navediya</p>
              <p className='text-sm font-medium text-black mt-2'>Gujrat</p>
              <p className='text-base font-medium text-black mt-2'>Won ₹5 lakh</p>
            </div>
          </div>
          <div className='bg-[#4CCD99] rounded-md p-4'>
            <p className='text-center text-sm font-normal text-black'>Participated in Rummy Premier Series and won 3 Lakhs . Winning withdrawn Instantly. Oscar Rummy has millions of real players and is the safest platform to play online rummy.</p>

            <div className='flex justify-center mt-4'><FaRegUserCircle style={{
              width: "4rem",
              height: "4rem",
              color: "black"
            }} /></div>

            <div className='flex justify-center flex-col items-center mt-4'>
              <p className='text-base font-medium text-black'>Utkarsha Raturi</p>
              <p className='text-sm font-medium text-black mt-2'>Dehradun</p>
              <p className='text-base font-medium text-black mt-2'>Won ₹1 lakh</p>
            </div>
          </div>
          <div className='bg-[#4CCD99] rounded-md p-4'>
            <p className='text-center text-sm font-normal text-black'>Participated in Rummy Premier Series and won 3 Lakhs . My winnings were withdrawn in my account instantly. Oscar Rummy has millions of real players and is the safest platform to play online rummy.</p>

            <div className='flex justify-center mt-4'><FaRegUserCircle style={{
              width: "4rem",
              height: "4rem",
              color: "black"
            }} /></div>

            <div className='flex justify-center flex-col items-center mt-4'>
              <p className='text-base font-medium text-black'>Abhinav Rathore</p>
              <p className='text-sm font-medium text-black mt-2'>Lucknow</p>
              <p className='text-base font-medium text-black mt-2'>Won ₹3 lakh</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PeopleSay
