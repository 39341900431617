import React from 'react'
import Footer from '../../LandingPage/components/Footer'

function Terms() {
  return (
             <div className="flex justify-center flex-col items-center bg-black">
             <div className="mt-8 font-semibold text-2xl text-white">Terms And Condition</div>
       
             <div className="w-[80%] mt-8">
               <p className="font-semibold text-lg text-white">Introduction: </p>
               <p className="mt-4 font-medium text-base text-white">
               These user terms & conditions (the "Agreement"), which includes those terms and conditions expressly set out below and those incorporated by reference including the terms of the Privacy Policy and the Game Rules (as defined in section 9 below) is an agreement between you (the "User", "you" and "your"), and and its licensors, subsidiaries, associates, affiliates and partners (the "Company", "us", "we" and "our") which governs your use of the products and services ("Services") provided by the Company through the www.EXPOSITION TECHPLAT Pvt Ltd.in website and mobile app on Android & iOS platforms (the "Platform") and associated software ("Software"). By using any of the Services you are agreeing to all the terms of this Agreement.
               </p>
               <p className="mt-4 font-medium text-base text-white">
               If any of the terms of the Agreement are determined to be unlawful, invalid, void, or unenforceable for any reason, it shall not affect the validity and enforceability of the remaining terms. Any failure or delay on our part to act or exercise any right or remedy with respect to a breach of any of the terms of this Agreement by you shall not be construed as a waiver of our right to act with respect to the said breach or any prior, concurrent, subsequent or similar breaches.
               </p>
             </div>
       
             <div className="w-[80%] mt-8">
               <p className="font-semibold  text-lg text-white">Agreement: </p>
               <p className="mt-4 font-medium text-base text-gray-700">
                 <li className = "text-white">
                 You should ensure that you read, understand, and agree with all the terms and conditions contained in the Agreement, before you may use the Services. You should only use the Services if you have read and understood the Agreement and agree to abide by its terms and conditions. If you have any questions about the Agreement please contact us at expositiontechplat@gmail.com
                 </li>
                 <li className = "text-white">
                 We reserve the right to amend, modify, or change any of the terms of this Agreement at any time, and it is advisable to regularly look at these terms. We shall give notice of such changes ("Notice") either by notification in the manner as the Company may deem fit or by posting the amended Agreement on the Platform. Such modifications could include changes to any of our Game Rules. If you do not agree with the terms of the amended Agreement, you may terminate your use of the Services by notifying us that you wish to terminate your Accounts. Your continued use of the Services will be deemed to constitute your acceptance of the changes to the Agreement.
                 </li>
       
                
               </p>
             </div>
       
             <div className="w-[80%] mt-8">
               <p className="font-semibold text-lg text-white">
               Ownership :
               </p>
               <p className="mt-4 font-medium text-base text-gray-700">
                 <li className = "text-white">
                 Use of the EXPOSITION TECHPLAT Pvt Ltd (EXPOSITION TECHPLAT Pvt Ltd.in) name and any of the other names or marks associated with the Platform, the Services, or the Software is strictly forbidden unless it is used with the express written permission of the Company. The Platform, the Services, and the Software, including its code, documentation, appearance, structure, and organization is an exclusive product of the Company, which retains all rights, tangible and intangible, to the EXPOSITION TECHPLAT Pvt Ltd.in website, the games therein, software, codes, copies, modifications, or merged parts.
                 </li>
                
       
                
               </p>
             </div>


             <div className="w-[80%] mt-8">
               <p className="font-semibold text-lg text-white">
               Eligibility :
               </p>
               <p className="mt-4 font-medium text-base text-gray-700">
                 <li className = "text-white">
                 In order to use the Services, a User must register for the virtual accounts in the manner as provided in detail under Section 6 of this Agreement, and truthfully and accurately provide all mandatory information
                 </li>
                
                 <li className = "text-white">
                 In order to participate in Practice Games (as defined in section 4.3 below), you must be at least 18 years old and competent to enter into a contract.
                 </li>
                 <li className = "text-white">
                 In order to participate in Cash Games you must be at least 18 years old, and must not suffer from any disability or impairment which would prevent you from assuming the responsibilities contained in the Agreement, or from participating in the Games which are offered as part of the Services.
                 </li>
                 <li className = "text-white">
                 In order to participate in Cash Games , You must be a resident of India, (as defined in section 4.3 below), you must be at least 18 years old and competent to enter into a contract.
                 </li>
                 <li className = "text-white">
                 Users who do not fulfill the above criteria are expressly barred from participating in Cash Games, and would not be entitled to receive any prizes which they may win as a result of participation in Cash Games.
                 </li>
                 <li className = "text-white">
                 You understand and accept that by viewing or using the Platform or availing of any Services, or using communication features on the Platform, you may be exposed to content posted by other Users which you may find offensive, objectionable or indecent. You may bring such content posted by other Users to our notice that you may find offensive, objectionable or indecent and we reserve the right to act upon it, at our sole discretion, as we may deem fit. The decision taken by us in this regard shall be final and binding on you.
                 </li>
                 <li className = "text-white">
                 The user agrees and acknowledges that he/she can only access the game from territories that are not classified as Restricted Territories. Any access to our website or mobile application by the user from a Restricted Territory through the usage of IP or GPS masking software such as Fake GPS location, Fake GPS GO Location Spoofer etc. will amount to a breach of our terms and conditions. This will lead to permanent blacklisting, deletion of the Username and closure of Accounts and also forfeiture of the entire Cash Balance, without any prior notice to the User. Further, such actions are also a breach of applicable laws for which the User shall be solely liable to penalties (monetary and penal) under applicable laws. The Company is not responsible for the consequences on the User for illegally accessing our platforms by breaching our security protocol.
                 </li>
                 <li className = "text-white">
                 You agree and acknowledge that you are not a politically exposed person.
                 </li>
                 <li className = "text-white">
                 You agree and acknowledge that you have not been convicted for any crime.
                 </li>
                 <li className = "text-white">
                 You agree and acknowledge that you are not on the UN Sanctions list or works for a company that is on the UN sanctions list.
                 </li>

                


                
       
                
               </p>
             </div>


             <div className="w-[80%] mt-8">
               <p className="font-semibold text-lg text-white">
               Description of Services :
               </p>
               <p className="mt-4 font-medium text-base text-gray-700">
                 <li className = "text-white">
                 We provide valid, registered Users the opportunity to access and play Games, including without limitation online Rummy, Fantasy Games and Skill based Games. The Company provides software and IT services to enable games of skill to be played with other individuals over the internet.
                 </li>
                
                 <li className = "text-white">
                 The Services provided by the Company are only intended to facilitate games of skill.
                 </li>
                 <li className = "text-white">
                 The Company levies a charge ("Service Charge") for Cash Games from all the users participated in said cash games, which would be inclusive of Service Tax / GST as may be applicable. The Service Charge may vary from time to time and from one Cash Game to another.
                 </li>
                 

                


                
       
                
               </p>
             </div>

             <div className="w-[80%] mt-8">
               <p className="font-semibold text-lg text-white">
               Description of Services :
               </p>
               <p className="mt-4 font-medium text-base text-gray-700">
                 <li className = "text-white">
                 We provide valid, registered Users the opportunity to access and play Games, including without limitation online Rummy, Fantasy Games and Skill based Games. The Company provides software and IT services to enable games of skill to be played with other individuals over the internet.
                 </li>
                
                 <li className = "text-white">
                 The Services provided by the Company are only intended to facilitate games of skill.
                 </li>
                 <li className = "text-white">
                 The Company levies a charge ("Service Charge") for Cash Games from all the users participated in said cash games, which would be inclusive of Service Tax / GST as may be applicable. The Service Charge may vary from time to time and from one Cash Game to another.
                 </li>
                 

                


                
       
                
               </p>
             </div>



             <div className="w-[80%] mt-8">
               <p className="font-semibold text-lg text-white">
               Your Account :
               </p>
               <p className="mt-4 font-medium text-base text-gray-700">
                 <li className = "text-white">
                 Each User's account shall be deemed to be compartmentalized into two virtual accounts (collectively, the "Accounts").
                 </li>
                
                 <li className = "text-white">
                 All deposits made by the Users for playing any game and any Bonus Amounts received in accordance with Section 23 of this Agreement through the mechanism as set out below in Section 7.2(a) shall reside in the first virtual account ("Deposits Account") and any winnings in Cash Games, shall reside in the second virtual account ("Winnings Account").
                 </li>
                 <li className = "text-white">
                 Your Accounts are to be used and accessed solely by you.
                 </li>
                 <li className = "text-white">
                 You undertake that all the information provided by you at the time of opening the Accounts, or provided by you subsequently upon request by the Company, is true, complete and correct. We are not obliged to cross check or verify information provided by you and we will not take any responsibility for any outcome or consequence as a result of you providing incorrect information or concealing any relevant information from us. You further undertake to provide proof relating to any such information, including copies of documents such as your PAN card, or address and identity proof, upon request by the Company. You understand that the Company may take steps to verify any such information provided by you. Your continued use of the Services, including but not limited to your ability to make Cash Credits and Cash Withdrawals in accordance with the terms of this Agreement, is subject to the Company's continued satisfaction that all such details provided by you are true, complete and correct.
                 </li>

                 <li className = "text-white">
                 You are prohibited from using the Services by means of Accounts registered in the name of any other individual.
                 </li>
                 <li className = "text-white">
                 You are prohibited from using the Services by means of Accounts registered in the name of any other individual.
                 </li>
                 <li className = "text-white">
                 Should you attempt to open more than one Accounts, under your own name or under any other name, or should you attempt to use the Services by means of any other person's Accounts, we will be entitled to close all your Accounts and bar you from accessing or using the Services in the future without notice. The Company reserves the right to monitor any effort to establish multiple accounts; in the event the Company discovers that you have opened more than one Account per person, all additional accounts will be closed without notice and continued violation will result in the termination of any and all of your Accounts and the Company may void any associated winnings.
                 </li>
                 <li className = "text-white">
                 You shall not share the password to your Accounts, or any other confidential or sensitive information associated with your Accounts, with any other individual. The Company is not responsible if your Accounts are accessed by others using your password or other confidential details or information. The Company assumes your responsibility in taking appropriate steps to select and secure your passwords by recommending to have a strong password.
                 </li>
                 <li className = "text-white">
                 You understand, represent and accept that your Accounts information or any related materials will not violate the rights of any third party rights or give rise to any claim that another party's rights have been or will be violated as a result of the Company's use or publication of your Accounts information. You also understand that you will not be entitled to receive any royalties for the use of your Accounts information by or through the Company.
                 </li>
                 <li className = "text-white">
                 You understand and accept that your participation in a Game does not create any obligation on the Company to give you a prize. Your winning is entirely dependent on your skill as a player vis-a-vis other players in the Game.
                 </li>
                 

                


                
       
                
               </p>
             </div>


            
       
           
       
        
       
            
             <div className='mt-8 w-full'>
                    <Footer/>
             </div>
            
           </div>
  )
}

export default Terms
