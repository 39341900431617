import React from "react";
import "./Navbar.css";
import { TEInput } from "tw-elements-react";
import { useNavigate } from "react-router-dom";

function NavbarMain() {
  const fileName = 'OscarRummy.apk'; // Name of your APK file in the public directory
  const navigate = useNavigate();
  const handleDownload = () => {
    const url = 'https://mygameapk.s3.amazonaws.com/OscarRummy.apk';
    const link = document.createElement('a');
    link.href = url;
    link.download = 'OscarRummy.apk'; // Specify the default filename for the download
    document.body.appendChild(link); // Append the link to the document body
    link.click(); // Programmatically click the link to trigger the download
    link.remove(); // Remove the link from the document
  };

  // https://drive.google.com/file/d/1_HOX5k4-O6BBjfX2j9kHp-5FIhS2vt8w/view?usp=sharing
  return (
    <div>
      <div className="main-nav">
        <div className="flex items-center justify-between">
          <div className="logo-header-div cursor-pointer" onClick={()  =>  navigate("/")}>
             <p className="" >
          Oscar Rummy 

             </p>
             </div>


             <div className="flex justify-center mt-4 mb-4">
      <button
              type="button"
              className="inline-block log-button  rounded bg-[#4CCD99] px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-[#4CCD99]  hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-[#4CCD99]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-[#4CCD99]  active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              onClick={handleDownload}
            >
              Download App
            </button>
      </div>
          {/* <div className="flex items-center gap-4">
            <div className="input-div">
              <TEInput
                type="text"
                id="exampleFormControlInput1"
                label="Email/Mobile No"
                className="input-col"
              ></TEInput>
            </div>

            <div className="input-div">
              <TEInput
                type="text"
                id="exampleFormControlInput1"
                label="Password"
                className="input-col"
              ></TEInput>
            </div>

            <button
              type="button"
              className="inline-block log-button  rounded bg-[#4CCD99] px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-[#4CCD99]  hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-[#4CCD99]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-[#4CCD99]  active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Login
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default NavbarMain;
