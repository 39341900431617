import React from 'react'

function DataRevenue() {
  return (
    <div className="flex justify-center flex-col items-center bg-black">


      <div className="w-[80%] mt-8">
        <p className="font-semibold text-lg text-white">Cancellation of Games </p>
        <p className="mt-4 font-medium text-base text-white">
          EXPOSITION TECHPLAT Pvt Ltd is not responsible for any loss resulting from the cancellation of any games due to Service outages caused by the failures of our service providers, computer viruses, natural disasters or any other reason that is beyond our control. You acknowledge that you will not be entitled to any refund due to cancellation of games caused by factors that are beyond the reasonable control of EXPOSITION TECHPLAT Pvt Ltd.
        </p>
      </div>




      <div className="w-[80%] mt-8">
        <p className="font-semibold text-lg text-white">Online Transactions </p>
        <p className="mt-4 font-medium text-base text-white">
          Transactions to transfer money to your EXPOSITION TECHPLAT Pvt Ltd cash account have to be made through third-party payment gateways. EXPOSITION TECHPLAT Pvt Ltd is not responsible for the denial or rejection of any transactions as they are subject to the terms and conditions of the third-party payment gateways. EXPOSITION TECHPLAT Pvt Ltd may reverse any transactions, and the specific amount will in such a case be refunded to the financial instrument/platform that was used by you to perform the transaction.
        </p>
      </div>



      <div className="w-[80%] mt-8">
        <p className="font-semibold text-lg text-white">Account Validation </p>
        <p className="mt-4 font-medium text-base text-white">
          EXPOSITION TECHPLAT Pvt Ltd has the right to check or validate the personal information of the players from time to time. The process of validation can be done through a phone call or via email. If we are not able to get in touch with you at first, several attempts will be made to establish contact with you. In the event that the validation is unsuccessful, or we are unable to contact you after making all possible attempts, we reserve the right to suspend your account and refund the amount lying in your account to the financial instrument/platform that you used to make the payment online.
        </p>
      </div>


      <div className="w-[80%] mt-8">
        <p className="font-semibold text-lg text-white">Promotions </p>
        <p className="mt-4 font-medium text-base text-white">
          Rummy contests, offers, bonuses, tournaments and games in the Promotion Section on EXPOSITION TECHPLAT Pvt Ltd may get cancelled or discontinued at any moment without any prior notice. EXPOSITION TECHPLAT Pvt Ltd disclaims any liability, except the refund of the entry fee wherever applicable.
        </p>
      </div>


      <div className="w-[80%] mt-8">
        <p className="font-semibold text-lg text-white">Promotions </p>
        <p className="mt-4 font-medium text-base text-white">
          We operate as a responsible gaming company and encourage our players to play responsibly. We carefully monitor the playing activities of our players and alert them if they develop any compulsive behaviour. We take precautionary measures like the following to make sure that we offer a good online rummy gaming experience.

          People below 18 years of age are not permitted to create an account on our Website.

          People below 18 years of age are prohibited from playing our cash games.

          There is 24/7 monitoring of the players’ activities with an anti-fraud algorithm to detect fraudulent behaviour.

          Anti-collusion measures are implemented during every rummy game.

          Player Information is confidentially stored using high-level encryption for safety.

          Our Website has features that limit the online cash deposits and withdrawal amount.

          A maximum cash deposit limit is set on the site to discourage gaming obsession.

          There is KYC verification for player protection and game security.
        </p>
      </div>

      <div className="w-[80%] mt-8">
        <p className="font-semibold text-lg text-white">Refund Requests </p>
        <p className="mt-4 font-medium text-base text-white">
          In case of accidental or erroneous cash credits to any party, user or company, we can independently or you can request for a refund on grievance@oscarrummy.com  within two weeks of initiating the transaction. EXPOSITION TECHPLAT Pvt Ltd will process the refund if it is a genuine case of accidental or erroneous cash credit, which is clear from your request or after our own independent investigation. The refund amount will be credited within two weeks to the financial instrument/platform that was used to make the corresponding transaction.
        </p>
      </div>
      <div className="w-[80%] mt-8">
        <p className="font-semibold text-lg text-white">Voluntary Termination </p>
        <p className="mt-4 font-medium text-base text-white">
          You may cease to use our gaming services and terminate your account by emailing us at info.EXPOSITION TECHPLAT Pvt Ltd with a clear explanation of your intention and duration from 48 hours to 6 months. After evaluating your case, at our discretion, EXPOSITION TECHPLAT Pvt Ltd will refund to you the entire cash amount available in your account within 10 working days and terminate your account. Please note, that any account actions you take after emailing your request for terminating your account, including, but not limited to, playing cash games, tournaments, depositing and withdrawing money, will be deemed as actions taken solely by you and EXPOSITION TECHPLAT Pvt Ltd shall not be responsible for your actions.
        </p>
      </div>
      <div className="w-[80%] mt-8">
        <p className="font-semibold text-lg text-white">Inactive Accounts and Unused Funds </p>
        <p className="mt-4 font-medium text-base text-white">
          If your account has been inactive for over 365 days and has an unused cash balance, EXPOSITION TECHPLAT Pvt Ltd shall remind you two times to activate your account. To activate your account, you must log in and use your cash balance by playing a cash game. After the reminders, if you do not activate your account your cash balance shall be forfeited to EXPOSITION TECHPLAT Pvt Ltd . All reminders shall be sent to the email or phone number listed on your profile, as part of the account details and information you submitted to EXPOSITION TECHPLAT Pvt Ltd .

          Accounts found in breach of our Terms and Conditions shall receive no reminders, and the unused funds in such accounts shall be subject to the terms of our Breach and Consequences policy.
        </p>
      </div>

    </div>
  )
}

export default DataRevenue
