import React from "react";
import Footer from "../../LandingPage/components/Footer";

function MainComponet() {
  return (
    <div className="flex justify-center flex-col items-center bg-black">
      <div className="mt-8 font-semibold text-2xl text-white">Privacy Policy</div>

      <div className="w-[80%] mt-8">
        <p className="font-semibold text-lg text-white">Objective: </p>
        <p className="mt-4 font-medium text-base text-white">
          The objective of this Privacy Policy is to protect the privacy of
          persons dealing with EXPOSITION TECHPLAT Pvt Ltd Private Limited
          ("EXPOSITION TECHPLAT Pvt Ltd") and to explain how we collect, use,
          disclose and store Information in accordance with the legal
          requirements and in order to mitigate operational risks.{" "}
        </p>
      </div>

      <div className="w-[80%] mt-8">
        <p className="font-semibold  text-lg text-white">Overview: </p>
        <p className="mt-4 font-medium text-base text-gray-700">
          <li className = "text-white">
            References to "we", "our" or "us" in this Policy refer to EXPOSITION TECHPLAT Pvt Ltd, that is responsible for processing your personal
            data, as indicated in any contract for products or services you may
            avail from us.{" "}
          </li>
          <li className = "text-white">
            For the purposes of this Policy, any reference to "Services" is a
            reference to all the products and services offered by EXPOSITION TECHPLAT Pvt Ltd and its subsidiaries, whether available online or
            offline, and any reference to "Sites" is a reference to any of the
            EXPOSITION TECHPLAT Pvt Ltd  websites (including any mobile version of
            such websites), whether operated under the banner of Exposition Techplat Pvt Ltd or a brand name that we own. "Terms of Services" refer
            to the terms and conditions of  as set out on Exposition Techplat
          </li>

          <li className = "text-white">
            EXPOSITION TECHPLAT Pvt Ltd  provides products and services via multiple
            domains. When you visit any of our sites, our web servers record
            anonymous information such as the time, date and URL of the request.
            This information assists EXPOSITION TECHPLAT Pvt Ltd  to improve the
            structure of its web sites and to monitor their performance.{" "}
          </li>
          <li className = "text-white">
            EXPOSITION TECHPLAT Pvt Ltd  may use 'cookies' on various web sites.
            Cookies are an industry standard and most major web sites use them.
            A cookie is a small text file that our web sites may place on your
            computer. Usually, cookies are used as a means for EXPOSITION TECHPLAT Pvt Ltd web sites to remember your preferences. As such,
            cookies are designed to improve your experience of EXPOSITION TECHPLAT Pvt Ltd web sites.{" "}
          </li>

          <li className = "text-white">
            In some cases, cookies may collect and store personal information
            about you. EXPOSITION TECHPLAT Pvt Ltd  extends the same privacy
            protection to your personal information, whether gathered via
            cookies or from other sources. You can adjust your Internet browser
            to disable cookies or to warn you when cookies are being used.
            However, if you disable cookies, you may not be able to access
            certain areas of our web sites or take advantage of the improved web
            site experience that cookies offer.{" "}
          </li>

          <li className = "text-white">
            EXPOSITION TECHPLAT Pvt Ltd  also collects Internet Protocol (IP)
            addresses. IP addresses are assigned to computers on the internet to
            uniquely identify them within the global network. EXPOSITION TECHPLAT Pvt Ltd collects and manages IP addresses as part of the
            service of providing internet session management and for security
            purposes.{" "}
          </li>
        </p>
      </div>

      <div className="w-[80%] mt-8">
        <p className="font-semibold text-lg text-white">
          Communication and Policy for DND / NDNC :{" "}
        </p>
        <p className="mt-4 font-medium text-base text-gray-700">
          <li className = "text-white">
            By using the Website and/or registering yourself at EXPOSITION TECHPLAT Pvt Ltd.in you
            authorize us to contact you via email or phone call or SMS or push
            notification and offer you our services, imparting product
            knowledge, offer promotional offers running on website & offers
            offered by the associated third parties, for which reasons,
            personally identifiable information may be collected. And
            irrespective of the fact if also you have registered yourself under
            DND or DNC or NCPR service, you still authorize us to give you a
            call from EXPOSITION TECHPLAT Pvt Ltd for the above-mentioned purposes till 365 days of
            your registration with us.{" "}
          </li>
          <li className = "text-white">
            This Privacy Policy covers EXPOSITION TECHPLAT Pvt Ltd's treatment of personally
            identifiable information that EXPOSITION TECHPLAT Pvt Ltd collects when you use EXPOSITION TECHPLAT Pvt Ltd's
            services.{" "}
          </li>

          <li className = "text-white">
            This policy also covers EXPOSITION TECHPLAT Pvt Ltd's treatment of personally identifiable
            information that EXPOSITION TECHPLAT Pvt Ltd's business partner shares with EXPOSITION TECHPLAT Pvt Ltd.{" "}
          </li>
          <li className = "text-white">
            This policy does not apply to the certain EXPOSITION TECHPLAT Pvt Ltd services, when you
            enter promotions. EXPOSITION TECHPLAT Pvt Ltd may also receive personally identifiable
            information from our business partners.{" "}
          </li>

          <li className = "text-white">
            When you registered with EXPOSITION TECHPLAT Pvt Ltd, we ask for your contact no,email.
            Once you register with EXPOSITION TECHPLAT Pvt Ltd and sign in to our services, you are
            not anonymous to us.{" "}
          </li>

          <li className = "text-white">
            Also during registration, you may be requested to register your
            mobile phone or other device to receive text messages, and other
            services to your wireless device.{" "}
          </li>

          <li className = "text-white">
            By registration you authorize us to send sms/email alerts to you for
            your login details and any other service requirements or some
            advertising messages/emails from us.{" "}
          </li>
        </p>
      </div>

      <div className="w-[80%] mt-8">
        <p className="font-semibold text-lg text-white">Assurance of Privacy: </p>
        <p className="mt-4 font-medium text-base text-gray-700">
          <li className = "text-white">
            Your privacy is of utmost importance to us and we at  hereby assure you that we enforce the highest levels
            of confidentiality and security.{" "}
          </li>
          <li className = "text-white">
            We seek to comply with all currently applicable legislation
            regarding the protection, security and confidentiality of personal
            data. In this Policy, we describe how we collect, use, disclose and
            protect personal data.
          </li>
          <li className = "text-white">
            We also outline the rights you may have in respect of your personal
            data held by us. The Policy relates to your personal data that we
            may access through your use of this website and in relation to any
            contract for goods or services or other commercial relationship we
            may have with you, or with anyone who provides you with access to
            any of our products and services (such as your employer) that may
            collect, store or otherwise process your personal data.
          </li>
          <li className = "text-white">
            When you provide any information to us which may include sensitive
            personal data or information at our site or otherwise through any
            other medium, you can be assured that such information stays with us
            and is divulged only in accordance with the applicable laws and to
            the authorized persons, agencies only.
          </li>
          <li className = "text-white">
            At www.hdworks.in, we want to meet your specific needs by fostering
            an interactive and personalized environment. Thus, we may ask for
            your other particulars and opinions so that we can continuously
            improve our relationship with you.
          </li>
          <li className = "text-white">
            To assure you of our commitment to privacy, we have adopted the
            following standards while delivering services to you.
          </li>
        </p>
      </div>

      <div className="w-[80%] mt-8">
        <p className="font-semibold text-lg text-white">
          How We collect Personal Information:{" "}
        </p>
        <p className="mt-4 font-medium text-base text-gray-700">
          <li className = "text-white">
            Directly from you, when you submit your personal details at
            hdworks.in or subscribe to our Services and execute an Agreement
            with EXPOSITION TECHPLAT Pvt Ltd . We collect or receive information from
            you when you sign up for our services, create an online account with
            us, make a purchase, request details or a call back, submit a
            technical, customer or billing support request, participate in a
            contest or survey, provide us with feedback or otherwise contact us.{" "}
          </li>
          <li className = "text-white">
            Payment Information related to your commercial and financial
            relationship with us, such as the services we provide to you, the
            telephone numbers you call and text, your payment history and your
            service history.
          </li>
          <li className = "text-white">
            From third parties such as EXPOSITION TECHPLAT Pvt Ltd related
            companies, credit reporting agencies or your representatives; we
            sometimes collect personal data about you from trusted third
            parties, in connection with Services that we provide to you or
            propose to provide to you, where appropriate and to the extent we
            have a justified basis to do so. These include fraud-prevention
            agencies, business directories, credit check reference/vetting
            agencies and connected network providers.
          </li>
          <li className = "text-white">
            Anyone who provides you with access to your Services may also
            provide us with your personal data in that context.
          </li>
        </p>
      </div>

      <div className="w-[80%] mt-8 pb-8">
        <p className="font-semibold text-lg text-white">
          How We use your Personal Information{" "}
        </p>
        <p className="mt-4 font-medium text-base text-gray-700">
          <li className = "text-white">Verify your identity. </li>
          <li className = "text-white">
            Assist you to subscribe to EXPOSITION TECHPLAT Pvt Ltd and/or its
            associate's products and services.
          </li>
          <li className = "text-white">
            Provide the services you require Administer and manage those
            services, including charging, billing and collecting debts.
          </li>
          <li className = "text-white">
            Inform you of ways the services provided to you could be improved or
            of additional products, services or plans that could be subscribed
            by you.
          </li>

          <li className = "text-white">
           To respond to your inquiries, to provide any information that you request, to address technical support tickets, and to provide customer service support.
          </li>

          <li className = "text-white">
           Conduct appropriate checks for credit-worthiness and for fraud.
          </li>
          <li className = "text-white">
          Research and develop EXPOSITION TECHPLAT Pvt Ltd  and/or its associates' products and services.
          </li>
          <li className = "text-white">
          To administer, monitor, improve and customize our Sites and Services, for our internal operations, including troubleshooting, network management and network optimization, research and analytical purposes, so that we can provide our customers with a better customer experience.
          </li>
          <li className = "text-white">
          Gain an understanding of your information and communication needs in order for EXPOSITION TECHPLAT Pvt Ltd  to provide you with a better service.
          </li>
          <li className = "text-white">
          Maintain and develop EXPOSITION TECHPLAT Pvt Ltd business systems and infrastructure, including testing and upgrading of these systems. 
          </li>
          <li className = "text-white">
          Also, your personal information is collected so that EXPOSITION TECHPLAT Pvt Ltd  can promote and market its or its associates or any third party services to you (including by way of direct mail, telemarketing, SMS and MMS messages).
          </li>
          <li className = "text-white">
          EXPOSITION TECHPLAT Pvt Ltd  does this to keep you informed of its products, services and special offers as also any promotional offers designed by EXPOSITION TECHPLAT Pvt Ltd  in collaboration with third parties.
          </li>
          <li className = "text-white">
          If you do not wish EXPOSITION TECHPLAT Pvt Ltd  to promote and market its products, services, and special offers to you, please contact our Customer Support.
          </li>
          <li className = "text-white">
          To defend any demands, claims and litigations or proceedings arising against EXPOSITION TECHPLAT Pvt Ltd .
          </li>
          <li className = "text-white">
          To investigate, prevent or take action regarding illegal activities, fraud, threats to our property or personnel and violations of our Terms of Use and/or applicable law and also to meet our legal and regulatory obligations.
          </li>
          <li className = "text-white">
          Carry out any activity in connection with a legal, governmental or regulatory requirement on us or in connection with legal proceedings, crime or fraud prevention, detection or prosecution & 
Transfer to any other body corporate or a person in India, or located in any other country, that ensures the same level of data protection that is adhered to by EXPOSITION TECHPLAT Pvt Ltd , provided such transfer of personal information is necessary for the performance of the lawful contract between EXPOSITION TECHPLAT Pvt Ltd  or any person on its behalf and such other body corporate or person in India, or located in any other country. 
          </li>
        </p>
      </div>
      <div className="w-full">
             <Footer/>
      </div>
     
    </div>
  );
}

export default MainComponet;
