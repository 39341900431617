import React from 'react'
import { Route, Routes } from 'react-router-dom'
import LandingPage from '../LandingPage'
import PrivacyPolicy from '../PrivacyPolicy'
import TermsAndCondition from '../TermsAndCondition'
import RevenuePolicy from '../RevenuePolicy'


function RoutingComponent() {
  return (
    <Routes>
        <Route path="/" element={ <LandingPage/> } />
        <Route path="/privacy-policy" element={ <PrivacyPolicy/> } />
        <Route path="/terms-and-condition" element={ <TermsAndCondition/> } />
        <Route path="/refund-policy" element={ <RevenuePolicy/> } />
        
        



        



        



         
       
      </Routes>
  )
}

export default RoutingComponent