import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";

function FooterHeading() {
  const navigate = useNavigate();
  const fileName = 'OscarRummy.apk'; // Name of your APK file in the public directory
  const handleDownload = () => {
    const url = 'https://mygameapk.s3.amazonaws.com/OscarRummy.apk';
    const link = document.createElement('a');
    link.href = url;
    link.download = 'OscarRummy.apk'; // Specify the default filename for the download
    document.body.appendChild(link); // Append the link to the document body
    link.click(); // Programmatically click the link to trigger the download
    link.remove(); // Remove the link from the document
  };
  return (
    <div className="">
      <div className="lg:flex lg:justify-between lg:items-center foot-small">
        <div className="w-[50%]">
          <div className="logo-header-div w-[8rem] text-center cursor-pointer" onClick={() => navigate("/")}>
            <p className="">Oscar Rummy</p>
          </div>
          <div className="mt-4">

            <p>
              Office No - 301/3 , Garg PLaza Bhera Exclave , Sunder Vihar , New Delhi , West Delhi - 110087 , Delhi, India.
            </p>
            <p className="mt-4">Email id : support@oscarrummy.com</p>

            <p className="mt-4">Phone: +91-9899975358</p>
            <p className="mt-4">© Oscar Rummy Game | Copyright 2024</p>
          </div>
        </div>
        <div className="w-[50%] lg:flex lg:flex-col lg:justify-end lg:items-end lg:gap-4 sm:flex sm:flex-col ">
          <p className="font-semibold text-xl cursor-pointer">Company Policy</p>
          <p className="font-semibold cursor-pointer" onClick={() => navigate("/privacy-policy")}>Privacy Policy</p>
          <p className="font-semibold cursor-pointer" onClick={() => navigate("/refund-policy")}>Refund Policy</p>
          <p className="font-semibold cursor-pointer" onClick={() => navigate("/terms-and-condition")}>Terms & Condition</p>
          {/* <p className="font-semibold">About Us</p> */}
          <p className="font-semibold cursor-pointer" onClick={handleDownload}>Download Oscar Rummy App</p>
          {/* <p className="font-semibold">Disclaimer</p> */}
        </div>
      </div>




    </div>
  );
}

export default FooterHeading;
