import React, { useState } from "react";
import FrequentlyAsked from "./FrequentlyAsked";
import "./Online.css";

function TabsData() {
  const [inData, setInData] = useState([
    {
      name: "Variants",
      image:
        "https://d22ueo28hfk252.cloudfront.net/Content/vers…on-Junglee-Rummy-17134326880811.webp?v=1713432688",
      data: "Rummy has not only managed to retain its popularity for generations but is also growing more popular by the day. One of the reasons for the game’s immense popularity is that it is available in different formats and variants. Though the variants have more or less the same objective, there are slight variations in the game rules that you need to be aware of before you play the game.",
      dataPoints: [
        {
          picture:"Points Rummy",
          heading: "Points Rummy",

          text: "Are you looking for a quick rummy online game? If so, this variant is the perfect fit for you. Each game is played for just one deal/round and each point has a pre-decided chip value, or rupee value in a cash game.",
        },

        {
          picture:"Deals Rummy",
          heading: "Deals Rummy",
          text: "This variant tests your gaming skills as well as patience. As the name suggests, the game is played for a fixed number of deals: 2 The player with the highest number of chips at the end of the final deal wins the rummy game.",
        },

        {
          picture:"Pool Rummy",
          heading: "Pool Rummy",
          text: "Do you think you have the skills to ace the game of rummy? Prove your mettle by playing the longest format of Indian rummy, which lasts for several deals. Every player whose score reaches 101 points (in 101 pool) or 201 points (in 201 pool) gets eliminated from the game. The player who is left alone at the table in the end is the winner.",
        },

        {
          picture:"Spin & Go",
          heading: "Spin & Go",
          text: "Spin & Go is a rummy variant meant exclusively for cash rummy players. It is a multiplayer rummy game played in a single deal format. It allows players to win a cash prize that is multiple times their entry fee for the game. The spinner determines the prize pool in Spin & Go before a game begins. In this variant, the winning amount can be from 2x to 10x of the game entry fee. The value of the prize pool is determined randomly by the spinner before the game starts.",
        },

        {
          picture:"Rummy Tournaments",

          heading: "Free and Cash Rummy Tournaments",
          text: "We bring you the most enjoyable online rummy games and tournaments 24X7. Play free games to have fun and sharpen your skills, or join cash games and tournaments to battle it out for incredibly big cash prizes. Bring your best game to the table and become a proud tournament champion. Our tournaments give you wonderful opportunities to win whopping big cash prizes as well as other coveted prizes like cars, motorbikes, and high-end smartphones.",
        },
      ],
    },
  ]);
  return (
    <div className="w-full flex justify-center pt-8 pb-8 ">
      <div className="w-[80%]  rounded-md p-4">
        {/* <div className="flex items-center small-tab gap-2 p-4">
          <div className="text-white text-sm text-center tab-wide shadow-lg bg-[#A45F66] p-2 rounded-md hover:bg-[#A45F66]">
            Rummy Game Variants On Oscar Rummy
          </div>
          <div className="text-white text-sm text-center tab-wide shadow-lg bg-[#4CCD99] p-2 rounded-md hover:bg-[#A45F66]">
            Learn How To Play Rummy
          </div>
          <div className="text-white text-sm text-center tab-wide shadow-lg bg-[#4CCD99] p-2 rounded-md hover:bg-[#A45F66]">
            Rummy Tips and Tricks
          </div>
          <div className="text-white text-sm text-center tab-wide shadow-lg bg-[#4CCD99] p-2 rounded-md hover:bg-[#A45F66]">
            Why Oscar Rummy Is Safest Site
          </div>
          <div className="text-white text-sm text-center tab-wide shadow-lg bg-[#4CCD99] p-2 rounded-md hover:bg-[#A45F66]">
            Online Rummy Is Absolutely Legal
          </div>
        </div> */}
  
<div className='flex justify-center'>

<p className='font-semibold text-3xl text-[#A45F66]'>Rummy Game Variants On Oscar Rummy</p>
</div>

        <div className="mt-4 bg-[#A45F66] p-4 mr-4 ml-4 mb-4 rounded-md">
          <div className="p-4 lg:flex tab-haeder-small gap-4">
            <img src = "https://static3.depositphotos.com/1005251/196/i/450/depositphotos_1969751-stock-illustration-gin-rummy.jpg" alt="" className="h-[16rem] rounded-md lg:w-[50%] sm:w-[100%] mb-2"/>
            <div className="lg:w-[50%] sm:w-[100%]">

            <p className="text-white text-sm">{inData[0].data}</p>
            </div>
          </div>
            {inData[0].dataPoints.map((item) => {
              return(

          <div className="p-4 flex items-center tab-data-small gap-4 mt-4">
             <div className="lg:flex lg:items-center sm:flex sm:justify-center sm:items-center gap-4">
                 <div className="bg-[#F4D35E] text-white p-4 rounded-full w-[7rem] h-[7rem] font-medium text-center flex justify-center items-center">{item.picture}</div>
             </div>

             <div>
              <p className="font-medium text-base text-white">{item.heading}</p>
              <p className="font-light text-sm text-white">{item.text}</p>
             </div>

          </div>
              )
            })}

<div className="flex justify-center mt-4 mb-4">
      <button
              type="button"
              className="inline-block log-button  rounded bg-[#4CCD99] px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-[#4CCD99]  hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-[#4CCD99]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-[#4CCD99]  active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Register For Free
            </button>
      </div>

        </div>
      

      </div>
    </div>
  );
}

export default TabsData;
