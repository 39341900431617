import React from 'react'
import Qualities from './Qualities'

function TrustedRummy() {
  return (
    <div className='bg-[#6f0000] w-full'>
      <div className='flex justify-center'>
             <p className='font-semibold text-3xl sm:text-center text-white'>India's Most Trusted Rummy Site</p>
      </div>
      <div className='flex justify-center'>

      <Qualities/>
      </div>
    </div>
  )
}

export default TrustedRummy
