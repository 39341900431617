import React from "react";
import { FaCcAmazonPay, FaCheck } from "react-icons/fa";

function Qualities() {
  return (
    <div className="bg-black rounded-md text-white w-[80%] mt-8">
      <div className=" p-4 lg:grid lg:grid-cols-2 lg:gap-4 sm:grid sm:grid-cols-1 sm:gap-4">
        <div className="flex items-center gap-4 pb-8 bor-qua mt-6">
          <div>

            <img src="https://www.silkrummy.com/wp-content/uploads/2020/12/best-promo.png"  style={{
                width: "5rem",
                height: "5rem",
              }} alt="" />
            {/* <FaCcAmazonPay
              style={{
                width: "4rem",
                height: "5rem",
              }}
            /> */}
          </div>
          <div>
            <p className="text-base font-semibold">
              Play the Most Loved Rummy Games
            </p>
            <div className="flex items-center gap-2">
           
              <FaCheck
                style={{
                  width: "1rem",
                  height: "1rem",
                  fontWeight: "400",
                }}
              />
              <p className="text-sm">Play Points, Pool & Deals Rummy</p>
            </div>
            <div className="flex items-center gap-2">
              <FaCheck />
              <p className="text-sm">Play 24X7 Online Rummy</p>
            </div>
            <div className="flex items-center gap-2">
              <FaCheck />
              <p className="text-sm">Free Tournaments Every Day</p>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-4 pb-8 bor-qua mt-6">
          <div>

          <img src="https://www.silkrummy.com/wp-content/uploads/2020/12/rummy-variant.png"  style={{
                width: "5rem",
                height: "5rem",
              }} alt="" />
            {/* <FaCcAmazonPay
              style={{
                width: "4rem",
                height: "5rem",
              }}
            /> */}
          </div>
          <div>
            <p className="text-base font-semibold">100% Safe and Secure</p>
            <div className="flex items-center gap-2">
              <FaCheck
                style={{
                  width: "1rem",
                  height: "1rem",
                  fontWeight: "400",
                }}
              />
              <p className="text-sm">RNG & No-Bot Certified Games</p>
            </div>
            <div className="flex items-center gap-2">
              <FaCheck />
              <p className="text-sm">100% Secure Transactions</p>
            </div>
            <div className="flex items-center gap-2">
              <FaCheck />
              <p className="text-sm">SSL & PCI DSS Compliant</p>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-4 pb-8 bor-qua mt-6">
          <div>

          <img src="https://www.silkrummy.com/wp-content/uploads/2020/12/best-rummy.png"  style={{
                width: "5rem",
                height: "5rem",
              }} alt="" />
            {/* <FaCcAmazonPay
              style={{
                width: "4rem",
                height: "5rem",
              }}
            /> */}
          </div>
          <div>
            <p className="text-base font-semibold">The Great Rummy Experience</p>
            <div className="flex items-center gap-2">
              <FaCheck
                style={{
                  width: "1rem",
                  height: "1rem",
                  fontWeight: "400",
                }}
              />
              <p className="text-sm">8 Crore+ Players* - Zero Wait Time</p>
            </div>
            <div className="flex items-center gap-2">
              <FaCheck />
              <p className="text-sm">Fastest Rummy Game Tables</p>
            </div>
            <div className="flex items-center gap-2">
              <FaCheck />
              <p className="text-sm">Seamless Gameplay</p>
            </div>
          </div>
        </div>


        <div className="flex items-center gap-4 pb-8 bor-qua mt-6">
          <div>
            {/* <FaCcAmazonPay
              style={{
                width: "4rem",
                height: "5rem",
              }}
            /> */}

<img src="https://www.silkrummy.com/wp-content/uploads/2020/12/18plus.png"  style={{
                width: "5rem",
                height: "5rem",
              }} alt="" />
          </div>
          <div>
            <p className="text-base font-semibold">Responsible Gaming</p>
            <div className="flex items-center gap-2">
              <FaCheck
                style={{
                  width: "1rem",
                  height: "1rem",
                  fontWeight: "400",
                }}
              />
              <p className="text-sm">Strict Fair Play & Responsible Gaming Policies</p>
            </div>
            <div className="flex items-center gap-2">
              <FaCheck />
              <p className="text-sm">Pre-defined Deposit Limit</p>
            </div>
            <div className="flex items-center gap-2">
              <FaCheck />
              <p className="text-sm">World-class Anti-Fraud System</p>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-4 pb-8 bor-qua mt-6">
          <div>

          <img src="	https://www.silkrummy.com/wp-content/uploads/2020/12/secure.png"  style={{
                width: "5rem",
                height: "5rem",
              }} alt="" />
            {/* <FaCcAmazonPay
              style={{
                width: "4rem",
                height: "5rem",
              }}
            /> */}
          </div>
          <div>
            <p className="text-base font-semibold">Best Rewards & VIP Services</p>
            <div className="flex items-center gap-2">
              <FaCheck
                style={{
                  width: "1rem",
                  height: "1rem",
                  fontWeight: "400",
                }}
              />
              <p className="text-sm">Exclusive Bonuses & Promotions</p>
            </div>
            <div className="flex items-center gap-2">
              <FaCheck />
              <p className="text-sm">Free Entry to VIP Tournaments</p>
            </div>
            <div className="flex items-center gap-2">
              <FaCheck />
              <p className="text-sm">24X7 Customer Support</p>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4 pb-8 bor-qua mt-6">
          <div>
            {/* <FaCcAmazonPay
              style={{
                width: "4rem",
                height: "5rem",
              }}
            /> */}
              <img src="https://www.silkrummy.com/wp-content/uploads/2020/12/fastest-withdraw.png"  style={{
                width: "5rem",
                height: "5rem",
              }} alt="" />


          </div>
          <div>
            <p className="text-base font-semibold">Fastest Withdrawals</p>
            <div className="flex items-center gap-2">
              <FaCheck
                style={{
                  width: "1rem",
                  height: "1rem",
                  fontWeight: "400",
                }}
              />
              <p className="text-sm">Fastest Withdrawal Processing</p>
            </div>
            <div className="flex items-center gap-2">
              <FaCheck />
              <p className="text-sm">Request Withdrawals 24x7</p>
            </div>
            <div className="flex items-center gap-2">
              <FaCheck />
              <p className="text-sm">Zero Withdrawal Fee</p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="flex justify-center mt-4 mb-4">
      <button
              type="button"
              className="inline-block log-button  rounded bg-[#4CCD99] px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-[#4CCD99]  hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-[#4CCD99]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-[#4CCD99]  active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Register For Free
            </button>
      </div> */}
    </div>
  );
}

export default Qualities;
