import React from 'react'
import Terms from './components/Terms'
import Navbar from '../LandingPage/components/Navbar'
import { useEffect } from 'react';

function TermsAndCondition() {

  useEffect(() => {
    // This will run only once after the initial render
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []); // Empty dependency array means it runs once after the first render  
  return (
    <div className=''>
      <div>
        <Navbar />
      </div>
      <Terms />
    </div>
  )
}

export default TermsAndCondition
