import React from 'react'
import DataRevenue from './components/DataRevenue'
import Navbar from '../LandingPage/components/Navbar'
import Footer from '../LandingPage/components/Footer'
import { useEffect } from 'react'

function RevenuePolicy() {
  useEffect(() => {
    // This will run only once after the initial render
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []); // Empty dependency array means it runs once after the first render  
  return (
    <div>

      <div>
        <Navbar />
      </div>

      <div className='pb-8 bg-black' >

        <DataRevenue />
      </div>

      <div>
        <Footer />
      </div>
    </div>
  )
}

export default RevenuePolicy
