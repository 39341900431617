import React from 'react'
import OnlineHeader from './components/OnlineHeader';
import TabsData from './components/TabsData';
import FrequentlyAsked from './components/FrequentlyAsked';

function Online() {
  return (
    <div className=''>

             <div className='w-full bg-[#4CCD99] pt-8 pb-8'>

      <OnlineHeader/>
             </div>

             <div className='bg-black'>
                 <TabsData/>         
             </div>

             <div className='bg-black pl-8 pt-4 pr-8 pb-8'>
              <FrequentlyAsked/>
             </div>
    </div>
  )
}

export default Online;
