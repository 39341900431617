import React from "react";

function OnlineHeader() {
  return (
    <div className="flex justify-center flex-col items-center pt-8">
      <div className="w-[80%]">
        <div className=" text-center">
          <p className="font-semibold text-3xl">
            Play Rummy Online on Oscar Rummy
          </p>
        </div>

        <div className="bg-black text-white p-4 rounded-md shadow-lg mt-8">
          <p className="font-medium text-base">
            A Trusted Platform Loved  by Million Players
          </p>
          <p className="font-normal text-sm mt-2">
            Oscar Rummy is a trusted online rummy platform loved 
            by million  rummy players from across the country. On our
            certified bot-free platform, you can play rummy online for free and
            for real money anytime and from anywhere. The Oscar Rummy app is
            known for providing the most authentic, immersive, and enjoyable
            rummy experience ever. We have world-class security systems to
            ensure fair play in each and every game. Our 100% secure platform
            ensures complete protection of players’ money and personal data. It
            uses smart algorithms and ultramodern features to detect and prevent
            attempts of collusion or any other suspicious activity at the
            source.
          </p>
        </div>

        <div className="bg-black text-white p-4 rounded-md shadow-lg mt-4">
          <p className="font-medium text-base">
            Popularity of the Rummy Card Game Online
          </p>
          <p className="font-normal text-sm mt-2">
            Rummy is an easy yet thrilling card game played with a standard deck
            of 52 cards plus a printed joker. Crores of people play the game
            every day online. Online rummy is a game of skill that offers
            unlimited entertainment and wonderful opportunities to win huge cash
            prizes. And unlike real-world card games, online rummy on Junglee
            Rummy is absolutely fair, and each player gets the exact same time
            to make moves and equal opportunities to win. Oscar Rummy provides
            every player with an amazing opportunity to play rummy online in the
            safest gaming environment. Our rummy app offers multiple rummy
            variants and formats, fulfilling the needs of players with all
            levels of skills and different preferences. We ensure that we
            deliver the most enjoyable and memorable gaming experience to every
            player. Oscar Rummy allows you to sharpen your rummy skills and
            strategies by playing free games as well as to showcase your skills
            and expertise in cash games and tournaments and win huge cash
            prizes. Winning online rummy games requires players to arrange their
            cards in valid sequences and sets according to rummy rules and make
            a valid declaration before their opponents. That needs learning
            rummy rules and developing playing strategies. Playing rummy online
            offers unlimited entertainment to people from all backgrounds and
            socio-economic classes, making it one of the most sought-after
            sources of entertainment in India.
          </p>
        </div>

        <div className="bg-black text-white p-4 rounded-md shadow-lg mt-4">
          <p className="font-medium text-base">Tournaments on Oscar Rummy</p>
          <p className="font-normal text-sm mt-2">
            Participate in rummy tournaments ranging from freerolls to cash
            tournaments hosted around the clock for you. You can try your hands
            at special tournaments, as mentioned:-
          </p>
          <p className="font-medium text-base mt-4">
            Rummy Premier League (RPL) 2024 Edition
          </p>
          <p className="font-normal text-sm mt-2">
            <li>Dates : 6th - 30 th Sept</li>
            <li>Total Prize Pool: Win from ₹15 Crore.</li>

            <p className="font-normal text-sm mt-2">
              You can also become a rummy PRO by winning these top 3 prizes:
            </p>

            <li>First Prize: ₹25 Lakh</li>
            <li>Second Prize: ₹12.5 Lakh</li>
            <li>Third Prize: ₹7.5 Lakh</li>
          </p>
        </div>
      </div>
    </div>
  );
}

export default OnlineHeader;
