import React from "react";
import "./MainPage.css";
import { TEInput } from "tw-elements-react";

function HeaderSection() {
  return (
    <div>
      <div className="lg:grid lg:grid-cols-4 lg:gap-4 md:grid md:grid-cols-4 md:gap-4 cas-back-img">
        <div></div>

        <div className="grid col-span-2">
          <div className="flex justify-center flex-col  items-center">
            <div className="back-morph">

            <p className="rupee-main">₹ 1001</p>
            <p className="wel-text">Welcome Bonus</p>
            </div>

            <div className="bg-black rounded-md  sm:ml-8 sm:mr-8 shadow-lg grid grid-cols-3 gap-2 pt-2 pb-2 pr-4 pl-4 mt-4 mb-8">
              <div className="flex justify-center flex-col items-center">
                <div className="parallelogram-container">
                  <div className="parallelogram text-center">1st Deposit</div>
                </div>

                <div>
                  <p className="text-white font-medium text-base">
                    ₹ 5000 Bonus and ₹ 250 intant cast
                  </p>
                </div>
              </div>

              <div className="flex  justify-center flex-col items-center mid-welcome">
                
                <div className="parallelogram-container">
                  <div className="parallelogram text-center">2nd Deposit</div>
                </div>
                <div>
                  <p className="text-white font-medium text-base">
                    ₹ 5000 Bonus and ₹ 250 intant cast
                  </p>
                </div>
              </div>

              <div className="flex justify-center flex-col items-center">
                <div className="parallelogram-container">
                  <div className="parallelogram text-center">3rd Deposit</div>
                </div>
                <div>
                  <p className="text-white font-medium text-base">
                    ₹ 5000 Bonus and ₹ 250 intant cast
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex justify-center items-center">

          <div className="bg-white h-52 w-[80%] rounded-md">
          <div className="input-div flex justify-center flex-col items-center mt-12">
              <TEInput
                type="text"
                id="exampleFormControlInput1"
                label="Mobile No"
                className="input-col"
              ></TEInput>

<button
              type="button"
              className="inline-block log-button mt-12  rounded bg-[#4CCD99] px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-[#4CCD99]  hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-[#4CCD99]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-[#4CCD99]  active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Login
            </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default HeaderSection;
