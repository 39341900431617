import React from 'react'
import HeaderSection from './HeaderSection'

function MainHeader() {
  return (
    <div >
    <HeaderSection/>
    </div>
  )
}

export default MainHeader
