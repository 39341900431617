import React, { useState } from "react";
import "./Online.css";
import { FaMinus, FaPlus } from "react-icons/fa";

function FrequentlyAsked() {
  const [ques, setQues] = useState([
    {
      question: "Is rummy legal to play in India?",
      open: false,
      answer:
        "Yes, the Supreme Court of India has declared rummy a skill game and ruled that playing a skill game like rummy for real money is a business activity, not betting or gambling. Therefore, it is absolutely legal to play online rummy for both cash and for free in India.",
    },
    {
      question: "What is a rummy account?",
      open: false,
      answer:
        "A rummy account is an in-app account for all registered players who wish to particip",
    },
    {
      question: "How can I play online rummy on Oscar Rummy?",
      open: false,
      answer:
        "Download the Oscar Rummy app for free and begin your rummy journey on Oscar Rummy. If you are new to the game, visit our “How To Play” section to learn the game rules and strategies.",
    },
    {
      question: "Is it safe to play on Oscar Rummy?",
      open: false,
      answer:
        "Absolutely! It is 100% safe to play rummy on the Oscar Rummy platform. Oscar Rummy is truested by millions, and multiple other foolproof features and technologies to make sure that all player accounts and transactions on Oscar Rummy are 100% safe and secure, and each and every game is completely fair.",
    },
    {
      question: "How do I download the oscar rummy app?",
      open: false,
      answer:
        "If you are downloading the cash rummy app using an Android phone, here are the steps to follow: (1)-> Click on the Download App Button on Navigation Menu (2)-> Tap “Install.” (3)-> Once the app is installed, tap “Open” to begin your rummy journey. ",
    },
  ]);

  const handleClick = (ques, data, i, setQues) => {
    let dataItem = [...ques];

    dataItem[i].open = !data.open;
    setQues(dataItem);
    dataItem = [];
  };
  return (
    <div className="w-full">
      <div className="flex justify-center">
        <div className="w-[80%]">
          <div className="borData text-white">
            <p className="font-semibold text-base text-white">
              Frequently Asked Questions
            </p>
          </div>
          {ques.map((data, i) => {
            return (
              <div className="mt-4 borData">
                <div
                  className="flex justify-between"
                  onClick={() => handleClick(ques, data, i, setQues)}
                >
                  <p className="text-white">{data.question}</p>
                  {data.open ? (
                    <FaMinus
                      style={{
                        color: "white",
                      }}
                    />
                  ) : (
                    <FaPlus
                      style={{
                        color: "white",
                      }}
                    />
                  )}
                </div>

                {data.open ? <p className="text-white">{data.answer}</p> : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FrequentlyAsked;
